.btn {
  display: inline-block;
  padding: 0.8rem 2rem;
  background-color: var(--primary);
  border-radius: 50px;
  text-align: center;
  font-size: 1rem;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.btn:before {
  content: "";
  position: absolute;
  height: 0;
  width: 100%;
  background: #ffffff;
  left: 0;
  bottom: 0;
  transition: all 0.3s;
  opacity: 0.3;
}

.btn:hover:before {
  height: 100%;
}

.btn:before:focus-visible {
  content: "";
  position: absolute;
  height: 0;
  width: 100%;
  background: #fff; /*#ff4c2b*/
  left: 0;
  bottom: 0;
  transition: all 0.3s;
  opacity: 0.3;
}

.btn:focus-visible:before {
  height: 100%;
}

a:focus-visible {
  outline: none;
}

.btn-outlined {
  display: inline-block;
  padding: 0.8rem 2rem;
  background: transparent;
  border-radius: 50px;
  text-align: center;
  font-size: 1rem;
  color: #000000;
  font-weight: 500;
  border: 1.5px solid var(--primary);
  outline: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.btn-outlined:before {
  content: "";
  position: absolute;
  height: 0;
  width: 100%;
  background: #bebebe;
  left: 0;
  bottom: 0;
  transition: all 0.3s;
  opacity: 0.3;
}

.btn-outlined:hover:before {
  height: 100%;
}

.btn-outlined:before:focus-visible {
  content: "";
  position: absolute;
  height: 0;
  width: 100%;
  background: #bebebe;
  left: 0;
  bottom: 0;
  transition: all 0.3s;
  opacity: 0.3;
}

.btn-outlined:focus-visible:before {
  height: 100%;
}

@media screen and (min-width: 2560px) {
  .btn {
    font-size: 1.3rem;
  }
}

.container {
  display: flex;
  gap: 2rem;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}
.row {
  display: flex;
  gap: 2rem;
}

.column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.column_mobile {
  display: none;
  flex-direction: column;
  gap: 2rem;
}

.column_left_border {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  padding-left: 2rem;
  border-left: 2px solid #dfe7ef;
}

.input_wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input_wrapper label {
  font-weight: 400;
  font-size: 0.9rem;
}

.desktop_btn {
  display: block;
  width: 100%;
}

.mobile_btn {
  display: none;
  width: 100%;
}

.desktop_btn button {
  width: 100%;
}

.mobile_btn button {
  width: 100%;
}

.datatable,
.datatable_full_w {
  box-shadow: 5px 8px 20px 0px rgba(191, 191, 191, 0.25);
}

.datatable td,
.datatable_full_w td {
  font-size: 0.9rem;
  padding: 0.735rem 0.5rem;
}

.datatable th,
.datatable_full_w th {
  padding: 0.735rem 0.5rem;
}

.auth {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  align-self: center;
  margin-top: 5rem;
}

.auth p {
  font-size: 1.25rem !important;
  font-weight: 400 !important;
  margin-bottom: 1rem;
}

.auth_box {
  display: flex;
  flex-direction: inherit;
  gap: inherit;
  width: 480px;
  align-self: center;
}

.auth_box input {
  height: 48px;
  padding: 0 15px;
  border-radius: 15px;
  font-size: 1.1rem;
  border: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-shadow: 5px 8px 20px 0px rgba(191, 191, 191, 0.25);
  color: var(--main-text);
}

/*---media---*/
@media screen and (max-width: 1280px) {
  .container {
    flex-direction: column;
  }
  .row {
    width: 100%;
  }
  .column {
    width: 50%;
  }
  .input_wrapper {
    width: 100%;
  }
  .input_wrapper span {
    width: 100%;
  }
  .column_left_border {
    padding-left: 0;
    padding-top: 2rem;
    border-left: none;
    border-top: 2px solid #dfe7ef;
  }
}

@media screen and (max-width: 1160px) {
  .auth_box input {
    padding: 0 12px;
    font-size: 1rem;
  }
}

@media screen and (max-width: 1000px) {
  .auth {
    margin-top: 0;
  }
}

@media screen and (max-width: 900px) {
  .auth {
    align-self: auto;
  }
  .auth_box {
    width: 100%;
    align-self: auto;
  }
}

@media screen and (max-width: 670px) {
  .row {
    flex-direction: column;
  }
  .column {
    width: 100%;
  }
  .desktop_btn {
    display: none;
  }
  .mobile_btn {
    display: block;
  }
}

@media screen and (max-width: 425px) {
  .desktop_datatable {
    display: block;
  }
  .column_mobile {
    display: flex;
  }
}

@media screen and (min-width: 2560px) {
  .auth_box input {
    font-size: 1.4rem;
  }
}
